import React from 'react';
import PageWrapper from '../../containers/PageWrapper';
import { WebsiteWrapper, Home } from '../../parts';

const HomePage = ({ location, pageContext }) => {
  const { lang, translations } = pageContext;

  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <Home location={location} />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default HomePage;
